import React, { PropsWithChildren } from "react";
import Flex from "./flex";
import useTheme from "../../themes/light";
import { useBreakpoint } from "../../hooks/use-breakpoint";

export const Container: CustomComponent<PropsWithChildren<{}>> = ({
  style,
  ...props
}) => {
  return (
    <Flex style={{ width: "100%", ...(style as object) }} {...props}>
      {props.children}
    </Flex>
  );
};

export const PageWidth: CustomComponent<
  PropsWithChildren<{
    maxWidth?: number;
    padding?: number;
  }>
> = ({ style, maxWidth, padding, ...props }) => {
  const layout = useTheme((theme) => theme.layout);
  const isLessThanSm = useBreakpoint("<", "sm");
  const isLessThanXs = useBreakpoint("<", "xs");

  let actualPadding =
    padding ||
    (isLessThanXs && layout.page.paddingXs) ||
    (isLessThanSm && layout.page.paddingSm) ||
    layout.page.padding;

  return (
    <Container
      style={{
        maxWidth: `min(${
          maxWidth || layout.page.width
        }px, calc(100% - ${actualPadding}px))`,
        ...(style as object),
      }}
      {...props}
    >
      {props.children}
    </Container>
  );
};

export const Center: CustomComponent<
  PropsWithChildren<{ horizontal?: boolean; vertical?: boolean }>
> = ({ style, horizontal = true, vertical = false, ...props }) => {
  return (
    <Container
      style={{
        justifyContent: horizontal ? "center" : "flex-start",
        alignItems: vertical ? "center" : "flex-start",
        ...(style as object),
      }}
      {...props}
    >
      {props.children}
    </Container>
  );
};
