import React, { useState } from "react";
import { Center, Container, PageWidth } from "../../components/general/containers";
import Typography from "../../components/general/typography";
import HouseTier2 from "../../components/svg/house-tier-2";
import HouseTier4 from "../../components/svg/house-tier-4";
import useTheme from "../../themes/light";
import PriceCard from "./price-card";
import HouseTier0 from "../../components/svg/house-tier-0";
import HouseTier5 from "../../components/svg/house-tier-5";
import Flex from "../../components/general/flex";
import Base from "../../components/general/base";
import PeriodSelector from "./period-selector";

const TestItem = ({ color = "red" }) => {
  return (
    <Base
      style={{
        boxShadow: "0 0 50px rgba(0,0,0,0.8) inset",
        width: "100px",
        height: "200px",
        background: color,
      }}
    />
  );
};

const REACT_APP_LINK_AUTHIEN: string = process.env.REACT_APP_LINK_AUTHIEN ?? '';
const REACT_APP_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID ?? '';
const REACT_APP_REDIRECT_URI: string = process.env.REACT_APP_REDIRECT_URI ?? '';

export type PaymentPeriodType = "monthly" | "quarterly" | "annual";

const Pricing: CustomComponent<{}> = () => {
  const { palette } = useTheme();
  const [paymentPeriod, setPaymentPeriod] =
    useState<PaymentPeriodType>("annual");

  const discounts = {
    monthly: 0,
    quarterly: 5,
    annual: 15,
  };

  function getPriceWithDiscount(
    price: number,
    paymentPeriod: PaymentPeriodType
  ): number {
    return price * getDiscountMultiplier(discounts[paymentPeriod]);
  }

  function getDiscountMultiplier(discount: number): number {
    return 1 - discount / 100;
  }

  const iconProps = {
    color: palette.primary.main,
    style: {
      height: "80px",
      width: "80px",
    },
  };

  return (
    <>
      <Center style={{ marginBottom: "40px" }}>
        <PageWidth style={{ justifyContent: "center" }}>
          <Typography
            id="pricing"
            variant="sectionTitle"
            color="primary.darkest"
            style={{
              textAlign: "center",
              "&:after, &:before": {
                margin: "0 10px",
                content: "'-'",
                verticalAlign: "center",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            Nossos planos
          </Typography>
        </PageWidth>
      </Center>
      <Center>
        <PageWidth
          style={{
            gap: "20px",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <Center>
            <PeriodSelector
              paymentPeriod={paymentPeriod}
              setPaymentPeriod={setPaymentPeriod}
            />
          </Center>

          <Flex
            style={{
              gap: "20px",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <PriceCard
              href={`${REACT_APP_LINK_AUTHIEN}/login/identity?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&scope=openid&response_type=code&period=${paymentPeriod}&plan=basic`}
              Icon={<HouseTier0 {...iconProps} />}
              title="BÁSICO"
              price={getPriceWithDiscount(115, paymentPeriod)}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 2 },
                { label: "Imóveis", quantity: 150 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 80.0 },
                { label: "Preço por imóvel adicional", value: 1.5 },
              ]}
              backgroundVariant={1}
            />

            <PriceCard
              href={`${REACT_APP_LINK_AUTHIEN}/login/identity?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&scope=openid&response_type=code&period=${paymentPeriod}&plan=advanced`}
              Icon={<HouseTier2 {...iconProps} />}
              title="DESBRAVADOR"
              price={getPriceWithDiscount(235, paymentPeriod)}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 5 },
                { label: "Imóveis", quantity: 500 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 40.0 },
                { label: "Preço por imóvel adicional", value: 0.4 },
              ]}
              backgroundVariant={2}
            />

            <PriceCard
              href={`${REACT_APP_LINK_AUTHIEN}/login/identity?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&scope=openid&response_type=code&period=${paymentPeriod}&plan=pro`}
              Icon={<HouseTier4 {...iconProps} />}
              title="PROFISSIONAL"
              price={getPriceWithDiscount(350, paymentPeriod)}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 10 },
                { label: "Imóveis", quantity: 2000 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 30.0 },
                { label: "Preço por imóvel adicional", value: 0.15 },
              ]}
              backgroundVariant={3}
            />

            <PriceCard
              href={`${REACT_APP_LINK_AUTHIEN}/login/identity?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&scope=openid&response_type=code&period=${paymentPeriod}&plan=premium`}
              Icon={<HouseTier5 {...iconProps} />}
              title="PREMIUM"
              price={getPriceWithDiscount(470, paymentPeriod)}
              discount={discounts[paymentPeriod]}
              features={[
                { label: "Usuários", quantity: 20 },
                { label: "Imóveis", quantity: 5000 },
                { label: "Contratos", quantity: "infinite" },
                { label: "Gestão de locação", check: true },
                { label: "Gestão de venda", check: true },
                { label: "Preço por usuário adicional", value: 20.0 },
                { label: "Preço por imóvel adicional", value: 0.08 },
              ]}
              backgroundVariant={4}
            />
          </Flex>
        </PageWidth>
      </Center>
      <div style={{ height: "20px" }}></div>
    </>
  );
};

export default Pricing;
