import React from "react";
import Button from "../../components/general/button";
import Flex from "../../components/general/flex";
import Typography from "../../components/general/typography";
import useTheme from "../../themes/light";
import { PaymentPeriodType } from ".";

const PeriodSelector: CustomComponent<{
  paymentPeriod: PaymentPeriodType;
  setPaymentPeriod: React.Dispatch<React.SetStateAction<PaymentPeriodType>>;
}> = ({ paymentPeriod, setPaymentPeriod, ...props }) => {
  const { palette } = useTheme();
  return (
    <Flex
      style={{
        background: palette.neutral.n0,
        padding: "5px",
        borderRadius: "5px",
        gap: "5px",
        border: `1px solid ${palette.neutral.n1}`,
        boxShadow: "3px 3px 10px rgba(0,0,0,0.1) inset",
      }}
    >
      <Button
        variant="outlined"
        style={{ padding: "10px 20px" }}
        selected={paymentPeriod === "monthly"}
        onClick={() => {
          setPaymentPeriod("monthly");
        }}
      >
        <Typography
          variant="text"
          style={{
            color: "inherit",
            fontWeight: "bold",
            fontFamily: "Roboto Condensed",
          }}
        >
          Mensal
        </Typography>
      </Button>
      <Button
        variant="outlined"
        style={{ padding: "10px 20px" }}
        selected={paymentPeriod === "quarterly"}
        onClick={() => {
          setPaymentPeriod("quarterly");
        }}
      >
        <Typography
          variant="text"
          style={{
            color: "inherit",
            fontWeight: "bold",
            fontFamily: "Roboto Condensed",
          }}
        >
          Trimestral{" "}
          <span
            style={{
              marginLeft: "5px",
              fontWeight: "bolder",
              color: palette.warning.light,
            }}
          >
            (-5%)
          </span>
        </Typography>
      </Button>
      <Button
        variant="outlined"
        style={{ padding: "10px 20px" }}
        selected={paymentPeriod === "annual"}
        onClick={() => {
          setPaymentPeriod("annual");
        }}
      >
        <Typography
          variant="text"
          style={{
            color: "inherit",
            fontWeight: "bold",
            fontFamily: "Roboto Condensed",
          }}
        >
          Anual
          <span
            style={{
              marginLeft: "5px",
              fontWeight: "bolder",
              color: palette.warning.light,
            }}
          >
            (-15%)
          </span>
        </Typography>
      </Button>
    </Flex>
  );
};

export default PeriodSelector;
