import React from "react";
import Base from "../../components/general/base";
import { Container } from "../../components/general/containers";
import { FlexColumn } from "../../components/general/flex";
import Typography from "../../components/general/typography";
import { Splash } from "../../components/svg/splashes";
import { useBreakpoint } from "../../hooks/use-breakpoint";
import { palette } from "../../themes/light";
import { IconType } from "react-icons";

const Feature: CustomComponent<{
  title: string;
  description: string;
  isLeft?: boolean;
  icon: IconType;
  splashIndex?: number;
}> = ({
  style,
  title,
  description,
  isLeft = true,
  icon: Icon,
  splashIndex = 0,
  ...props
}) => {
  const isLessThanXs = useBreakpoint("<", "xs");
  return (
    <Container
      style={{
        flexFlow: isLeft ? "row" : "row-reverse",
        gap: "20px",
        ...(style as object),
      }}
      {...props}
    >
      <Base
        style={{
          ...(isLessThanXs && {
            width: "50px",
            height: "50px",
          }),
          ...(!isLessThanXs && {
            width: "100px",
            height: "100px",
          }),
          display: "inline-block",
          position: "relative",
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <Splash
          splashIndex={splashIndex}
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: "0",
            zIndex: 0,
          }}
          startFill={palette.secondary.light}
          endFill={palette.primary.lightest}
        />
        <Icon
          size={"100%"}
          style={{
            width: "100%",
            height: "100%",
            zIndex: "1",
            position: "absolute",
            top: "0",
            padding: "15px",
          }}
          color={palette.primary.contrastText}
        />
      </Base>
      <FlexColumn
        style={{ flexGrow: 1, alignContent: "stretch", alignItems: "stretch" }}
      >
        <Typography
          variant="sectionTitle"
          color="primary.darkest"
          size="h5"
          style={{
            textAlign: isLeft ? "left" : "right",
            display: "block",
            marginBottom: "5px",
            flexShrink: 0,
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="text"
          color="neutral.n4"
          style={{
            ...(isLessThanXs && { textAlign: "justify" }),
            ...(!isLessThanXs && { textAlign: isLeft ? "left" : "right" }),
          }}
        >
          {description}
        </Typography>
      </FlexColumn>
    </Container>
  );
};

export default Feature;
