import React from "react";
import Base from "../../components/general/base";

const BackgroundSplash: CustomComponent<{}> = ({ style }) => {
  return (
    <Base
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        zIndex: 0,
        backgroundImage: "url('./icons/splashes-background-1.svg')",
        backgroundSize: "cover",
        backgroundAttachment: "fixed",
        opacity: 0.1,
        ...(style as object),
      }}
    />
  );
};

export default BackgroundSplash;
