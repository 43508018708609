import React from "react";
import useTheme from "../../themes/light";

export const Splash: CustomComponent<{
  fill?: string;
  startFill?: string;
  endFill?: string;
  size?: number;
  splashIndex: number;
}> = ({ splashIndex, ...props }) => {
  if (splashIndex % 3 === 0) return <Splash1 {...props} />;
  else if (splashIndex % 3 === 1) return <Splash2 {...props} />;
  else return <Splash3 {...props} />;
};

export const Splash1: CustomComponent<{
  fill?: string;
  startFill?: string;
  endFill?: string;
  size?: number;
}> = ({ fill, startFill, endFill, size = 50, style, ...props }) => {
  const { palette } = useTheme();

  startFill = startFill ? startFill : palette.primary.light;
  endFill = endFill ? endFill : palette.secondary.main;
  if (fill) startFill = endFill = fill;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 3806.19 3799.3"
      height={size}
      width={size}
      style={{ ...(style as object) }}
      {...(props as React.SVGProps<SVGSVGElement>)}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={startFill} />
          <stop offset="100%" stopColor={endFill} />
        </linearGradient>
      </defs>
      <path
        fill="url(#gradient)"
        d="M0.22 2063.38c9.44,-608.82 321.97,-1081.84 630.52,-1433.23 308.54,-351.39 613.09,-581.18 1189.62,-623.21 576.52,-42.04 1425.02,103.67 1749.41,530.04 324.4,426.38 124.7,1133.44 133.27,1767.66 8.57,634.23 225.41,1195.62 3.59,1379.68 -221.82,184.07 -882.3,-9.21 -1476.83,7.42 -594.53,16.63 -1123.12,243.18 -1534.23,-15.87 -411.12,-259.04 -704.78,-1003.67 -695.34,-1612.5z"
      />
    </svg>
  );
};

export const Splash2: CustomComponent<{
  fill?: string;
  startFill?: string;
  endFill?: string;
  size?: number;
}> = ({ fill, startFill, endFill, size = 50, style, ...props }) => {
  const { palette } = useTheme();

  startFill = startFill ? startFill : palette.primary.light;
  endFill = endFill ? endFill : palette.secondary.main;
  if (fill) startFill = endFill = fill;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 8984.31 9137.22"
      height={size}
      width={size}
      style={{ ...(style as object) }}
      {...(props as React.SVGProps<SVGSVGElement>)}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={startFill} />
          <stop offset="100%" stopColor={endFill} />
        </linearGradient>
      </defs>
      <path
        fill="url(#gradient)"
        d="M124.31 4175.19c234.68,-824.06 770.36,-1630.29 1475.58,-2505.73 705.19,-875.44 1579.9,-1820.09 2326.6,-1649.26 746.69,170.84 1365.39,1457.19 2293.43,2030.02 928.04,572.83 2165.41,432.12 2592.08,1467.23 426.71,1035.12 42.7,3246.01 -951.3,4442.87 -993.98,1196.86 -2597.96,1379.66 -4006.01,997.77 -1408.05,-381.87 -2620.21,-1328.44 -3259.46,-2222.68 -639.25,-894.25 -705.62,-1736.15 -470.92,-2560.22z"
      />
    </svg>
  );
};

export const Splash3: CustomComponent<{
  fill?: string;
  startFill?: string;
  endFill?: string;
  size?: number;
}> = ({ fill, startFill, endFill, size = 50, style, ...props }) => {
  const { palette } = useTheme();

  startFill = startFill ? startFill : palette.primary.light;
  endFill = endFill ? endFill : palette.secondary.main;
  if (fill) startFill = endFill = fill;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 2055.42 2069.13"
      height={size}
      width={size}
      style={{ ...(style as object) }}
      {...(props as React.SVGProps<SVGSVGElement>)}
    >
      <defs>
        <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <stop offset="0%" stopColor={startFill} />
          <stop offset="100%" stopColor={endFill} />
        </linearGradient>
      </defs>
      <path
        fill="url(#gradient)"
        d="M5.72 1012.48c39.97,-177.85 297.58,-258.32 457.47,-431.93 159.89,-173.62 222.07,-440.41 473.02,-537.8 250.94,-97.4 690.65,-25.41 906.05,186.33 215.41,211.73 206.52,563.21 210.97,876.57 4.44,313.37 22.21,588.62 -206.53,758.01 -228.73,169.39 -703.96,232.91 -999.32,194.8 -295.36,-38.11 -410.83,-177.86 -557.4,-385.35 -146.57,-207.5 -324.23,-482.75 -284.25,-660.61z"
      />
    </svg>
  );
};
