import React from "react";
import { CSSInterpolation } from "@emotion/css";

const HouseTier2: CustomComponent<{ color?: string; style?: CSSInterpolation }> = ({
  color = "black",
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="295.478"
      height="201.105"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 -1600 15790 10747"
      style={{ ...(style as object) }}
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M804 10675c-157 0-285-121-285-271s128-271 285-271v541zm14182 0H804v-541h14182v541zm0-541c157 0 285 121 285 271s-128 271-285 271v-541z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="570"
        d="M3363 10364V7583c0-222 181-404 404-404h1180c222 0 404 181 404 404v2781m1175-3185h2052v1988H6526zm684-5151h2052v2638L7210 3201z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="570"
        d="M804 5555l4618-3295c143-102 326-102 469 0l4618 3295m-9009-497v5306"
      ></path>
      <path
        fill={color}
        fillRule="nonzero"
        d="M9573 5229c0-157 107-285 239-285s239 128 239 285h-479zm0 5101V5229h479v5101h-479zm479 0c0 157-107 285-239 285s-239-128-239-285h479z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="570"
        d="M9894 5555h4889m0 0l-914-2330m-4607 0h4607m475 2330v4809m-3297-3783h2052v1346h-2052z"
      ></path>
      <path fill="none" d="M0 0h15790v10747H0z"></path>
    </svg>
  );
};

export default HouseTier2;
