import React from "react";
import Base from "../../components/general/base";
import Button from "../../components/general/button";
import Card from "../../components/general/card";
import Flex, { FlexColumn } from "../../components/general/flex";
import Separator from "../../components/general/separator";
import Typography from "../../components/general/typography";
import useTheme from "../../themes/light";
import separateValueToStrings from "../../utils/separate-decimal-number";
import { motion, AnimatePresence } from "framer-motion";

import { BiCheck, BiInfinite, BiX } from "react-icons/bi";

const PriceCard: CustomComponent<{
  Icon: React.ReactElement;
  title: string;
  price: number;
  discount: number;
  features: Array<{
    label: string;
    quantity?: number | "infinite";
    check?: boolean;
    value?: number;
  }>;
  variant?: "filled" | "outlined";
  backgroundVariant?: number;
  href: string;
}> = ({
  Icon,
  title,
  price,
  features,
  variant = "filled",
  backgroundVariant = 1,
  href,
  discount,
  style,
}) => {
  const palette = useTheme((state) => state.palette);
  const breakpoints = useTheme((state) => state.breakpoints);
  const priceSplitted = separateValueToStrings(price);

  const contrastColor = variant === "outlined" ? palette.primary.main : "white";
  return (
    <Card
      component="a"
      href={href}
      style={{
        position: "relative",
        overflow: "hidden",
        ...(variant === "filled" && {
          border: "none",
          background: `linear-gradient(-45deg, ${palette.primary.main} 0%, ${palette.secondary.main} 100%)`,
          color: "white",
        }),
        ...(variant === "outlined" && {
          borderColor: palette.primary.main,
        }),
        transition: "transform 0.2s",
        "&:hover": {
          transform: "scale(1.02)",
          background: palette.secondary.main,
          cursor: "pointer",
        },
        [`@media(max-width:${breakpoints.sm}px)`]: {
          minWidth: "200px",
        },
        [`@media(min-width:${breakpoints.sm}px)`]: {
          minWidth: "200px",
          maxWidth: "300px",
        },
        textDecoration: "none",
        ...(style as object),
      }}
    >
      <AnimatePresence>
        <Base
          component={motion.div}
          key={`animation-${Math.random()}`}
          style={{
            display: discount > 0 ? "block" : "none",
            position: "absolute",
            left: "calc(50% - 40px)",
            top: "15px",
            width: "100%",
            background: palette.warning.light,
            textAlign: "center",
            transform: "rotate(30deg)",
            zIndex: 2,
            fontFamily: "Roboto Condensed",
            fontWeight: "bolder",
            fontSize: `${15 + discount * 0.8}px`,
            boxShadow: "3px 3px 5px rgba(0,0,0,0.2)",
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0 }}
        >
          -{discount}%
        </Base>
      </AnimatePresence>
      <Base
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "absolute",
          zIndex: 0,
          backgroundImage: `url('./icons/splashes-background-v${
            (backgroundVariant % 4) + 1
          }.svg')`,
          backgroundSize: "cover",
          backgroundPosition: "1px 1px",
          opacity: 0.3,
        }}
      />
      <FlexColumn
        style={{
          width: "100%",
          border: "1px solid white",
          borderRadius: "8px",
          alignItems: "center",
          overflow: "hidden",
          padding: "10px",
          position: "relative",
          zIndex: 1,
          flexGrow: 1,
          ...(variant === "outlined" && { borderColor: palette.primary.main }),
        }}
      >
        {React.createElement(Icon.type, {
          ...Icon.props,
          color: contrastColor,
        })}
        <Typography
          variant="text"
          size="h5"
          color={contrastColor}
          font="Roboto"
          style={{ margin: "1px 0" }}
        >
          {title}
        </Typography>
        <Separator opacity={1} variant="light" style={{ margin: "10px 0" }} />
        <Typography
          variant="highlight"
          color={contrastColor}
          font="Roboto"
          size="h6"
          style={{
            alignItems: "center",
          }}
        >
          R$
          <Typography size="h3">{priceSplitted[0]}</Typography>,
          {priceSplitted[1]}/mês
        </Typography>
        <FlexColumn
          style={{
            marginBottom: "10px",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
          }}
        >
          {features.map(({ label, quantity, check, value }) => {
            let valueSplitted;
            if (value) valueSplitted = separateValueToStrings(value);

            return (
              <Flex
                key={title + "-" + label}
                style={{
                  justifyContent: "space-between",
                  width: "100%",
                  border: "1px solid rgba(255,255,255,0.5)",
                  borderWidth: "0 0 1px 0",
                  gap: "5px",
                  padding: "2px 0",
                }}
              >
                <Typography
                  variant="text"
                  font="Roboto"
                  color={contrastColor}
                  style={{ textAlign: "left" }}
                >
                  {label}:
                </Typography>
                <Typography
                  variant="text"
                  font="Roboto"
                  color={contrastColor}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  {quantity && quantity !== "infinite" && quantity}
                  {quantity === "infinite" && <BiInfinite />}
                  {check === true && <BiCheck />}
                  {check === false && <BiX />}
                  {valueSplitted && (
                    <>
                      <span style={{ fontSize: "0.8em" }}>R$</span>
                      {valueSplitted[0]},{valueSplitted[1]}
                    </>
                  )}
                </Typography>
              </Flex>
            );
          })}
        </FlexColumn>
        <Button variant={variant === "outlined" ? "outlined" : "none"}>
          <Typography font="Roboto" size="text" color="primary.dark">
            ASSINAR
          </Typography>
        </Button>
      </FlexColumn>
    </Card>
  );
};

export default PriceCard;
