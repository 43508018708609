import React from "react";
import { Center, PageWidth } from "../../components/general/containers";
import Flex from "../../components/general/flex";
import Spacer from "../../components/general/spacer";
import Typography from "../../components/general/typography";
import useTheme from "../../themes/light";
import BackgroundSplash from "../banner/background-splash";
import { FiFacebook, FiInstagram, FiLinkedin } from "react-icons/fi";
import { useBreakpoint } from "../../hooks/use-breakpoint";

const REACT_APP_LINK_MIDIA_INSTAGRAM: string =
  process.env.REACT_APP_LINK_MIDIA_INSTAGRAM ?? "";
const REACT_APP_LINK_MIDIA_FACEBOOK: string =
  process.env.REACT_APP_LINK_MIDIA_FACEBOOK ?? "";

const Footer: CustomComponent<{}> = () => {
  const { gradient } = useTheme();
  const isLessThanXs = useBreakpoint("<", "xs");
  return (
    <>
      <Spacer height="20px" />
      <Center
        style={{
          flexFlow: "column",
          alignItems: "center",
          background: gradient.darkBackground,
          position: "relative",
          boxShadow: "inset 0px 20px 10px rgba(0,0,0,0.5)",
          padding: "40px 0 20px 0",
        }}
      >
        <BackgroundSplash style={{ zIndex: 0 }} />
        <PageWidth
          style={{
            flexFlow: "column",
            alignItems: "center",
            gap: "30px",
            position: "relative",
            zIndex: 1,
          }}
        >
          <img src="logo-white.svg" style={{ height: "30px" }} />

          <Flex
            style={{
              justifyContent: "center",
              width: "100%",
              gap: "30px",
              ...(isLessThanXs && { flexFlow: "column", alignItems: "center" }),
            }}
          >
            <Typography
              component={"a"}
              target="_blank"
              href={REACT_APP_LINK_MIDIA_INSTAGRAM}
              variant="text"
              style={{ textDecoration: "inherit" }}
            >
              <FiInstagram style={{ marginRight: "7px" }} /> Instagram
            </Typography>

            <Typography
              component={"a"}
              target="_blank"
              href={REACT_APP_LINK_MIDIA_FACEBOOK}
              variant="text"
              style={{ textDecoration: "inherit" }}
            >
              <FiFacebook style={{ marginRight: "7px" }} /> Facebook
            </Typography>

            {/* <Typography
                component={"a"}
                variant="text"
                style={{ textDecoration: "inherit" }}
              >
                <FiLinkedin style={{ marginRight: "7px" }} /> Linkedn
              </Typography> */}
          </Flex>

          <Typography variant="text" style={{ textAlign: "center" }}>
            Copyright © 2023 · K4D Inc · All Rights Reserved
          </Typography>
        </PageWidth>
      </Center>
    </>
  );
};

export default Footer;
