import React from "react";
import Base from "../../components/general/base";
import Button, { ButtonVariants } from "../../components/general/button";
import { PropsWithChildren } from "react";

const NavItem: CustomComponent<
  PropsWithChildren<{
    direction?: "horizontal" | "vertical";
    buttonVariant?: ButtonVariants;
    href?: string;
  }>
> = ({
  style,
  direction = "horizontal",
  buttonVariant = "transparent",
  href,
  ...props
}) => {
  return (
    <Base
      style={{
        padding: 5,
        cursor: "pointer",
        ...(style as object),
      }}
      {...props}
    >
      <a href={href}>
        <Button
          variant={buttonVariant}
          style={{
            padding: "7px 20px",
            fontFamily: "inherit",
            fontSize: "inherit",
            ...(direction === "vertical" && {
              padding: "20px 20px",
            }),
          }}
        >
          {props.children}
        </Button>
      </a>
    </Base>
  );
};

export default NavItem;
