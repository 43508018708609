import { css } from "@emotion/css";
import React, { PropsWithChildren, SVGProps } from "react";

const Base: CustomComponent<PropsWithChildren<{}>> = ({
  component: Component = "div",
  style,
  ...props
}) => {
  return <Component className={css(style)} {...props}></Component>;
};

export const SvgBase: CustomComponent<SVGProps<SVGSVGElement>> = ({
  ...props
}) => {
  return <Base component="svg" {...props} />;
};

export default Base;
