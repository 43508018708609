import { FlexColumn } from "./flex";
import React, { PropsWithChildren } from "react";

const Card: CustomComponent<PropsWithChildren<{ style?: object }>> = ({
  children,
  style,
  ...props
}) => {
  return (
    <FlexColumn
      style={{
        border: "1px solid gray",
        boxShadow: "5px 5px 5px rgba(0,0,0,0.1)",
        borderRadius: "8px",
        padding: "15px",
        flexGrow: 1,
        background: "white",
        alignItems: "center",
        flexBasis: "0",
        ...style,
      }}
      {...props}
    >
      {children}
    </FlexColumn>
  );
};

export default Card;
