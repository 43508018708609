import React from "react";
import { Center, PageWidth } from "../../components/general/containers";
import { useBreakpoint } from "../../hooks/use-breakpoint";
import useTheme from "../../themes/light";
import { useLayoutEffect, useState } from "react";
import { FiMenu } from "react-icons/fi";
import NavItem from "./nav-item";
import Separator from "../../components/general/separator";
import { useScreenStore } from "../../context/screen-store";

const REACT_APP_LINK_AUTHIEN: string = process.env.REACT_APP_LINK_AUTHIEN ?? '';
const REACT_APP_CLIENT_ID: string = process.env.REACT_APP_CLIENT_ID ?? '';
const REACT_APP_REDIRECT_URI: string = process.env.REACT_APP_REDIRECT_URI ?? '';

const Nav: CustomComponent<{}> = ({ style, ...props }) => {
  const isLessThanSm = useBreakpoint("<", "sm");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const { width: screenWidth } = useScreenStore();

  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useLayoutEffect(() => {
    window.addEventListener("scroll", handleScroll, false);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useLayoutEffect(() => {
    setIsMenuOpen(false);
  }, [screenWidth]);

  const { layout, gradient } = useTheme();
  return (
    <Center
      style={{
        zIndex: 2,
        width: "100%",
        height: `${layout.navHeight}px`,
        display: "flex",
        alignItems: "center",
        position: "fixed",
        fontFamily: "Ubuntu",
        color: "white",
        ...((scrollPosition > 0 || (isMenuOpen && isLessThanSm)) && {
          background: gradient.darkBackground,
          boxShadow: "0 7px 7px rgba(0,0,0,0.3)",
        }),
        transition: "background 0.2s",
        ...(style as object),
      }}
    >
      <PageWidth
        style={{
          gap: "20px",
          height: "100%",
          alignItems: "center",
          position: "relative",
          fontSize: ".8rem",
        }}
      >
        <img src="logo-white.svg" style={{ height: "30px" }} />
        <span style={{ flexGrow: 1 }}> </span>
        {!isLessThanSm && (
          <>
            <NavItem href="#">Início</NavItem>
            <NavItem href="#features">Recursos</NavItem>
            <NavItem href="#pricing">Preços</NavItem>
            <NavItem
              buttonVariant="gradient"
              href={`${REACT_APP_LINK_AUTHIEN}/login/identity?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&scope=openid&response_type=code`}
            >
              Login
            </NavItem>
          </>
        )}
        {isLessThanSm && (
          <NavItem
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{ padding: 0, margin: 0 }}
          >
            <FiMenu size={"30px"} />
          </NavItem>
        )}
      </PageWidth>
      <SuspendedNav isMenuOpen={isMenuOpen} isLessThanSm={isLessThanSm} />
    </Center>
  );
};

const SuspendedNav: CustomComponent<{
  isMenuOpen: boolean;
  isLessThanSm: boolean;
}> = ({ isMenuOpen, isLessThanSm, style, ...props }) => {
  const { gradient } = useTheme();
  return (
    <Center
      vertical={true}
      style={{
        position: "absolute",
        right: 0,
        top: "100%",
        background: gradient.darkBackground,
        flexFlow: "column",
        opacity: isMenuOpen && isLessThanSm ? "1" : "0",
        transform: isMenuOpen && isLessThanSm ? "scale(1, 1)" : "scale(1, 0)",
        transformOrigin: "top",
        transition: "transform 0.2s, opacity 0.2s",
        boxShadow: "0 7px 7px rgba(0,0,0,0.3)",
        ...(style as object),
      }}
    >
      <Separator variant="light" />
      <PageWidth
        style={{
          flexFlow: "column",
          alignItems: "stretch",
          textAlign: "right",
          padding: "10px 0",
          fontSize: "1rem",
        }}
      >
        <NavItem direction="vertical" href="#">
          Início
        </NavItem>
        <Separator variant="light" />
        <NavItem direction="vertical" href="#features">
          Recursos
        </NavItem>
        <Separator variant="light" />
        <NavItem direction="vertical" href="#pricing">
          Preços
        </NavItem>
        <Separator variant="light" />
        <NavItem direction="vertical" href={`${REACT_APP_LINK_AUTHIEN}/login/identity?client_id=${REACT_APP_CLIENT_ID}&redirect_uri=${REACT_APP_REDIRECT_URI}&scope=openid&response_type=code`}>
          Login
        </NavItem>
      </PageWidth>
    </Center>
  );
};

export default Nav;
