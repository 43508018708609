function iterateObjectByStringPath(
  path: string,
  obj: Record<string, unknown>
): unknown {
  if (typeof obj !== "object") return;
  const keys = path.split(".");
  let value: unknown = obj;
  for (const element of keys) {
    value = (value as Record<string, unknown>)[element];
    if (value === undefined) {
      return undefined;
    }
  }
  return value;
}

export default iterateObjectByStringPath;
