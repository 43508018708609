import React from "react";
import { PropsWithChildren, useLayoutEffect } from "react";
import { create } from "zustand";

type ScreenStore = {
  width: number;
  setWidth: (width: number) => void;
};

export const useScreenStore = create<ScreenStore>((set) => ({
  width: typeof window !== "undefined" ? window.innerWidth : 0,
  setWidth: (width) => set({ width: width }),
}));

export const ScreenStoreProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  useLayoutEffect(() => {
    // Update the screenWidth whenever the window is resized
    const handleResize = () =>
      useScreenStore.getState().setWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <>{children}</>;
};
