import React from "react";
import Base from "./base";

const Spacer: CustomComponent<{
  height?: string;
  opacity?: number;
}> = ({ height, style, ...props }) => {
  return (
    <Base
      style={{
        height: height ? height : "1px",
        ...(style as object),
      }}
      {...props}
    />
  );
};

export default Spacer;
