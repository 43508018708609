import React from "react";

import Base from "./base";

const Separator: CustomComponent<{
  variant?: "dark" | "light";
  opacity?: number;
}> = ({ variant = "dark", opacity = 0.2, style, ...props }) => {
  return (
    <Base
      style={{
        height: "1px",
        width: "100%",
        borderStyle: "solid",
        borderWidth: "1px 0 0 0",
        ...(variant === "dark" && { borderColor: `rgba(0,0,0,${opacity})` }),
        ...(variant === "light" && {
          borderColor: `rgba(255,255,255,${opacity})`,
        }),
        ...(style as object),
      }}
      {...props}
    />
  );
};

export default Separator;
