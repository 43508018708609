import React from "react";

import useTheme from "../../themes/light";
import { SVGProps } from "react";
import { SvgBase } from "../general/base";

const HorizontalWave: CustomComponent<
  SVGProps<SVGSVGElement> & { color?: string; height?: string }
> = ({ color, height, ...props }) => {
  const { palette } = useTheme();
  if (!color) color = palette.neutral.n0;
  if (!height) height = "75px";
  return (
    <SvgBase
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      width="100%"
      height={height}
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      preserveAspectRatio="none"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 80298324 7230788"
      {...props}
    >
      <path
        fill={color}
        d="M0 6478851C4784327 2676611 9380909 23516 13921932 76h18775c553255-1988 1105823 34987 1657702 113871 11930304 1704695 24873362 10765443 45233040 5035393 7865756-2213748 16120730 2777978 19466876 1329511v751936H1v-751936z"
      />
    </SvgBase>
  );
};

export default HorizontalWave;
