import React from "react";
import { Center, PageWidth } from "../../components/general/containers";
import Feature from "./feature";
import Typography from "../../components/general/typography";
import {
  BiExpand,
  BiMobileVibration,
  BiDollarCircle,
  BiUser,
  BiShield,
  BiShareAlt,
} from "react-icons/bi";
import Spacer from "../../components/general/spacer";
import HorizontalWave from "../../components/svg/horizontal-wave";
import useTheme from "../../themes/light";

const Features: CustomComponent<{}> = () => {
  const { palette } = useTheme();
  const backgroundColor = palette.primary.ultraLight;
  return (
    <>
      <Spacer height="20px" />
      <Center>
        <PageWidth style={{ justifyContent: "center" }}>
          <Typography
            id="features"
            variant="sectionTitle"
            color="primary.darkest"
            style={{
              textAlign: "center",
              "&:after, &:before": {
                margin: "0 10px",
                content: "'-'",
                verticalAlign: "center",
                display: "flex",
                alignItems: "center",
              },
            }}
          >
            Os Benefícios do Nosso Serviço
          </Typography>
        </PageWidth>
      </Center>
      <Center style={{ display: "flex", flexFlow: "column" }}>
        <HorizontalWave
          color={backgroundColor}
          style={{
            marginTop: "20px",
            height: "calc(15px + 4vw)",
            maxHeight: "75px",
          }}
        />
        <Center
          style={{ background: backgroundColor, padding: "30px 0 50px 0" }}
        >
          <PageWidth
            maxWidth={800}
            style={{
              display: "flex",
              flexFlow: "column",
              gap: "40px",
            }}
          >
            <Feature
              splashIndex={1}
              icon={BiExpand}
              title="Solução Completa em um só lugar"
              description="Obtenha o controle total da sua empresa com um sistema completo que oferece soluções para diversas atividades e desafios diários. Livre-se das tarefas repetitivas e concentre-se na geração de valor para sua empresa."
            />
            <Feature
              splashIndex={2}
              icon={BiUser}
              isLeft={false}
              title="Cuide dos seus clientes"
              description="Com nosso CRM, otimize o relacionamento com seus clientes e gerencie todas as suas interações em um só lugar e ainda melhore a eficiência da sua equipe de vendas permitindo que eles gerenciem facilmente seus leads, mantenham o contato com clientes em potencial e trabalhem em conjunto para fechar negócios."
            />
            <Feature
              splashIndex={3}
              icon={BiDollarCircle}
              title="Controle financeiro simplificado"
              description="Facilite a visão sobre o fluxo de caixa da sua empresa, controle despesas e receitas, gerencie pagamentos e recebimentos e tenha acesso a relatórios e indicadores importantes para a tomada de decisões."
            />
            <Feature
              splashIndex={4}
              icon={BiMobileVibration}
              isLeft={false}
              title="Disponibilidade onde quer que esteja"
              description="Esteja preparado a todo momento com um sistema que te acompanha em qualquer lugar. Nosso site já está preparado para seus dispositivos móveis e oferece uma experiência de usuário intuitiva e fácil de usar para que você nunca perca uma oportunidade de negócio."
            />
            <Feature
              splashIndex={5}
              icon={BiShield}
              title="Proteja seus dados"
              description="Entendemos que a segurança dos seus dados é uma preocupação fundamental e levamos isso muito a sério. Por isso, nossa plataforma conta com diversas medidas de proteção e privacidade para garantir que todas as suas informações estejam sempre seguras."
            />
            <Feature
              splashIndex={6}
              icon={BiShareAlt}
              isLeft={false}
              title="Integrações?"
              description="Integre facilmente com os principais portais imobiliários e aproveite a facilidade em publicar seus anúncios! Já pensou também em como seria vantajoso aliar todos esses benefícios com ferramentas comerciais como o WhatsApp? Com essa integração, você pode se comunicar com seus clientes de maneira mais ágil e eficiente, compartilhando informações sobre imóveis e agendando visitas de forma rápida e segura."
            />
          </PageWidth>
        </Center>
        <HorizontalWave
          color={backgroundColor}
          style={{
            marginTop: "-1px",
            marginBottom: "20px",
            transform: "rotate(180deg)",
            height: "calc(15px + 4vw)",
            maxHeight: "75px",
          }}
        />
      </Center>
    </>
  );
};

export default Features;
