import { create } from "zustand";
const layout = {
  page: {
    width: 1024,
    padding: 100,
    paddingSm: 80,
    paddingXs: 40,
  },
  navHeight: 75,
};

export const breakpoints = {
  xs: 450, // extra small devices (portrait phones)
  sm: 768, // small devices (landscape phones)
  md: 960, // medium devices (tablets)
  lg: 1280, // large devices (desktops)
  xl: 1920, // extra large devices (large desktops)
};

export const palette = {
  primary: {
    ultraLight: "#e6f5fc",
    lightest: "#99d6f1",
    light: "#4db8e7",
    main: "#0099dd",
    dark: "#006b9b",
    darkest: "#003d58",
    ultraDark: "#001f2c",
    contrastText: "#FFFFFF",
  },
  secondary: {
    ultraLight: "#e6f9f8",
    lightest: "#99e7e3",
    light: "#4dd4cd",
    main: "#00c2b8",
    dark: "#008881",
    darkest: "#004e4a",
    ultraDark: "#002725",
    contrastText: "#FFFFFF",
  },
  background: {
    default: "#EEEEEE",
    paper: "#FFFFFF",
    dark: "#0d1728",
  },
  /* neutral: {
    n1: "#F3F4F6",
    n2: "#E5E7EB",
    n3: "#D1D5DB",
    n4: "#A6A6A6",
    n5: "#6B7280",
    n6: "#636363",
    n7: "#525252",
    n8: "#2b2b2b",
    n9: "#141414",
    main: "#D1D5DB",
    light: "#E5E7EB",
    lightest: "#f8f8f8",
    dark: "#6B7280",
    contrastText: "#525252",
  }, */
  neutral: {
    n0: "#eff2f3",
    n1: "#cfd8dc",
    n2: "#b0bec5",
    n3: "#8097a2",
    n4: "#607d8b",
    n5: "#4d646f",
    n6: "#3a4b53",
    n7: "#263238",
    n8: "#13191c",
    n9: "#0a0c0e",
  },
  action: {
    active: "#6B7280",
    focus: "rgba(55, 65, 81, 0.12)",
    hover: "rgba(55, 65, 81, 0.04)",
    selected: "rgba(55, 65, 81, 0.08)",
    disabledBackground: "rgba(55, 65, 81, 0.12)",
    disabled: "rgba(55, 65, 81, 0.26)",
  },
  divider: "#E6E8F0",
  success: {
    main: "#14B8A6",
    light: "#43C6B7",
    dark: "#0E8074",
    contrastText: "#FFFFFF",
  },
  info: {
    main: "#2196F3",
    light: "#64B6F7",
    dark: "#0B79D0",
    contrastText: "#FFFFFF",
  },
  warning: {
    main: "#FFB020",
    light: "#FFBF4C",
    dark: "#B27B16",
    contrastText: "#FFFFFF",
  },
  error: {
    main: "#D14343",
    light: "#DA6868",
    dark: "#922E2E",
    contrastText: "#FFFFFF",
  },
  text: {
    primary: "#121828",
    secondary: "#65748B",
    disabled: "rgba(55, 65, 81, 0.48)",
  },
};

export const font = {
  genera: { fontFamily: "Genera SemiBold, Tahoma" },
  roboto: { fontFamily: "Roboto Condensed, Arial" },
  calibri: { fontFamily: "Calibri" },
  ubuntu: { fontFamily: "Ubuntu, Calibri" },
};

export const size = {
  h1: { fontSize: "3.78rem" },
  h2: { fontSize: "3.15rem" },
  h3: { fontSize: "2.1rem" },
  h4: { fontSize: "1.58rem" },
  h5: { fontSize: "1.31rem" },
  h6: { fontSize: "1rem" },
  text: { fontSize: "0.9rem" },
};

export const variant = {
  title: {
    ...font.genera,
    ...size.h2,
    fontWeight: "bold",
    color: "white",
  },
  subtitle: {
    ...font.roboto,
    ...size.h5,
    fontWeight: "100",
    color: palette.neutral.n5,
  },
  sectionTitle: {
    ...font.genera,
    ...size.h3,
    fontWeight: "bold",
    color: palette.neutral.n8,
  },
  text: {
    fontFamily: "Ubuntu",
    fontSize: "16px",
    color: palette.neutral.n5,
  },
  highlight: {
    ...font.genera,
    ...size.h2,
    color: palette.primary.main,
    fontWeight: "bold",
  },
  tinyDescription: {
    ...font.roboto,
    ...size.text,
    color: palette.text.secondary,
  },
  button: {
    ...font.roboto,
    fontWeight: "bold",
  },
};

export const typography = {
  font,
  size,
  variant,
};

export const gradient = {
  main: `linear-gradient(141deg, ${palette.secondary.main} 0%, ${palette.primary.main} 100%)`,
  darkBackground:
    "linear-gradient(141deg, rgba(13,23,40,1) 0%, rgba(17,20,26,1) 100%)",
};

type Theme = {
  breakpoints: any;
  layout: any;
  palette: any;
  typography: any;
  gradient: any;
};

export const theme = {
  breakpoints,
  layout,
  palette,
  typography,
  gradient,
};

const useTheme = create<Theme>(() => theme);

export default useTheme;
