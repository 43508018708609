import useTheme from "../../themes/light";
import iterateObjectByStringPath from "../../utils/iterate-object-by-string-path";
import React, { PropsWithChildren } from "react";
import Base from "./base";

const Typography: CustomComponent<
  PropsWithChildren<{
    variant?: string;
    font?: string;
    size?: string;
    color?: string;
  }>
> = ({
  variant: variantKey,
  font: fontKey,
  size: sizeKey,
  color: colorKey,
  style,
  children,
  ...props
}) => {
  const palette = useTheme((state) => state.palette);
  const { font, size, variant } = useTheme((state) => state.typography);

  let parsedColor;
  if (colorKey) parsedColor = iterateObjectByStringPath(colorKey, palette);

  let color = parsedColor ? parsedColor : colorKey;

  return (
    <Base
      component={"span"}
      style={{
        display: "inline-flex",
        ...(variantKey && variant[variantKey]),
        ...(fontKey && font[fontKey.toLowerCase()]),
        ...(sizeKey && size[sizeKey]),
        ...(color && { color: color }),
        ...(style as object),
      }}
      {...props}
    >
      {children}
    </Base>
  );
};

export default Typography;
