import React from "react";
import Base from "../../components/general/base";
import Button from "../../components/general/button";
import { Center, PageWidth } from "../../components/general/containers";
import { FlexColumn } from "../../components/general/flex";
import Typography from "../../components/general/typography";
import useTheme from "../../themes/light";
import { useBreakpoint } from "../../hooks/use-breakpoint";
import BackgroundSplash from "./background-splash";
import Spacer from "../../components/general/spacer";

const Banner: CustomComponent<{}> = () => {
  const { layout, gradient } = useTheme();
  const isLessThanSm = useBreakpoint("<", "sm");
  const isLessThanXs = useBreakpoint("<", "xs");

  return (
    <>
      <Center
        style={{
          backgroundColor: "rgb(13,23,40)",
          background: gradient.darkBackground,
          paddingTop: `calc(${layout.navHeight}px + 30px)`,
          zIndex: 0,
          position: "relative",
        }}
      >
        <BackgroundSplash style={{ zIndex: 0 }} />
        <PageWidth style={{ position: "relative", zIndex: 1 }}>
          <FlexColumn
            style={{
              fontSize: "30px",
              textAlign: "center",
              ...(isLessThanSm && {
                width: "100%",
                textAlign: "center",
              }),
              ...(!isLessThanSm && {
                width: "50%",
              }),
            }}
          >
            <Typography
              variant="title"
              style={{
                marginBottom: "20px",
              }}
            >
              <Typography
                component="span"
                style={{ ...(isLessThanSm && { fontSize: "0.5em" }) }}
              >
                O Futuro Está na Gestão 100% online
              </Typography>
            </Typography>
            <Base
              style={{
                background:
                  'url("images/_2782ea5d-fab1-4229-a61f-397391c3870b.jpg")',
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "100px",
                maskImage: 'url("images/abstract-1.svg")',
                WebkitMaskImage: 'url("images/abstract-1.svg")',
                maskSize: "100% 100%",
                WebkitMaskSize: "100% 100%",
                ...(isLessThanSm && {
                  position: "relative",
                  width: "100%",
                  height: "60vw",
                  marginBottom: "20px",
                }),
                ...(!isLessThanSm && {
                  position: "absolute",
                  left: "calc(50% + 50px)",
                  width: "700px",
                  height: "calc(100% + 60px)",
                }),
              }}
            />
            <Typography variant="subtitle">
              Simplifique sua gestão imobiliária e aumente sua eficiência com
              nossa plataforma
            </Typography>
            <a href="#pricing">
              <Button
                variant="gradient"
                style={{
                  width: "100%",
                  height: "75px",
                  borderRadius: "15px",
                  boxShadow: "0px 3px 20px 0px rgba(0,0,0,0.3)",
                  position: "relative",
                  top: "calc(75px / 2)",
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="button"
                  size="h4"
                  font="Ubuntu"
                  style={{
                    ...(isLessThanXs && {
                      fontSize: "calc(13px + 3vw)",
                    }),
                  }}
                >Aumente a Eficiência Agora</Typography>
              </Button>
            </a>
          </FlexColumn>
        </PageWidth>
      </Center>
      <Spacer height="100px" />
    </>
  );
};

export default Banner;
