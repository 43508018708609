import React from "react";
import { CSSInterpolation } from "@emotion/css";

const HouseTier4: CustomComponent<{ color?: string; style?: CSSInterpolation }> = ({
  color = "black",
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="295.478"
      height="201.105"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 -1700 17592 11973"
      style={{ ...(style as object) }}
    >
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="635"
        d="M403 11592h16785m-9499 0V8449c0-248 202-450 450-450h1315c248 0 450 202 450 450v3143M8043 3078h1506v1596H8043z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="635"
        d="M5237 4164L8624 493c105-114 239-114 344 0l3387 3671m-704-1h5537m0 0l-1018-2596m530 2596v7429M6118 3208v8384m5356-8384v8384M5940 4163H403m0 0l1019-2596m6210 0H1422zm8538 0H9960M892 4163v7429m12135-3207h2286v1500h-2286zm-8462 0H2279v1500h2286zm8462-3059h2286v1500h-2286zm-8462 0H2279v1500h2286z"
      ></path>
      <path fill="none" d="M0 0h17592v11973H0z"></path>
    </svg>
  );
};

export default HouseTier4;
