import React from "react";
import { CSSInterpolation } from "@emotion/css";

const HouseTier0: CustomComponent<{ color?: string; style?: CSSInterpolation }> = ({
  color = "black",
  style,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="295.478"
      height="201.105"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 14253 13020"
      style={{ ...(style as object) }}
    >
      <path
        fill={color}
        fillRule="nonzero"
        d="M11545 12508c142 0 257 91 257 203s-115 203-257 203v-406zm-8838 0h8838v406H2707v-406zm0 406c-142 0-257-91-257-203s115-203 257-203v406z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="515"
        d="M9197 12638v-2474c0-201-164-365-365-365H7767c-201 0-365 164-365 365v2474M6341 9800H4489v1794h1852zm-617-4650H3871v2381l1853-1322z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="515"
        d="M11506 8334L7338 5359c-129-92-295-92-424 0L2746 8334m8132-449v4753M3374 7885v4753"
      ></path>
      <path fill="none" d="M0 0h14253v13020H0z"></path>
    </svg>
  );
};

export default HouseTier0;
