import React from "react";
import { CSSInterpolation } from "@emotion/css";

const HouseTier5: CustomComponent<{
  color?: string;
  style?: CSSInterpolation;
}> = ({ color = "black", style }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="295.478"
      height="201.105"
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 18622 17012"
      style={{ ...(style as object) }}
    >
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="672"
        d="M427 16608h17768m-16414 0h9580V1134H1781zm9580-11734h5672v11734h-5672z"
      ></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="672"
        d="M3493 12815h2353v1912H3493zm3803 21h2353v3772H7296zm5724-5933h2353v2353h-2353zm0 3760h2353v2353h-2353z"
      ></path>
      <path fill="none" d="M0 0h18622v17012H0z"></path>
      <path
        fill="none"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="22.926"
        strokeWidth="672"
        d="M3493 9326h2353v1912H3493zm0-3489h2353v1912H3493zm0-3489h2353v1912H3493zm3803 6978h2353v1912H7296zm0-3489h2353v1912H7296zm0-3489h2353v1912H7296z"
      ></path>
    </svg>
  );
};

export default HouseTier5;
