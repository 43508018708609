import { useLayoutEffect, useState } from "react";
import { useScreenStore } from "../context/screen-store";
import { breakpoints } from "../themes/light";

type Operation = "<" | ">" | "<=" | ">=";

const operations: Record<
  Operation,
  (actualWidth: number, breakpoint: number) => boolean
> = {
  "<": (actualWidth, breakpoint) => actualWidth < breakpoint,
  ">": (actualWidth, breakpoint) => actualWidth > breakpoint,
  "<=": (actualWidth, breakpoint) => actualWidth <= breakpoint,
  ">=": (actualWidth, breakpoint) => actualWidth >= breakpoint,
};

export function useBreakpoint(
  operator: keyof typeof operations,
  breakpoint: keyof typeof breakpoints
): boolean {
  const screenWidth = useScreenStore((state) => state.width);

  const checkBreakpoint = () => {
    const breakpointWidth = breakpoints[breakpoint];
    const operation = operations[operator];
    return operation(screenWidth, breakpointWidth);
  };

  const [matchesBreakpoint, setMatchesBreakpoint] = useState(checkBreakpoint());
  useLayoutEffect(() => {
    setMatchesBreakpoint(checkBreakpoint());
  }, [screenWidth]);

  return matchesBreakpoint;
}
