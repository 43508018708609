import React, { PropsWithChildren } from "react";
import useTheme from "../../themes/light";
import Base from "./base";

export type ButtonVariants =
  | "filled"
  | "outlined"
  | "gradient"
  | "transparent"
  | "none";

const Button: CustomComponent<
  PropsWithChildren<{
    variant?: ButtonVariants;
    selected?: boolean;
  }>
> = ({ children, variant = "filled", selected = false, style, ...props }) => {
  const { palette, gradient } = useTheme();
  return (
    <Base
      component="button"
      data-selected={selected}
      style={{
        cursor: "pointer",
        border: "none",
        background: "white",
        borderRadius: "4px",
        width: "100%",
        padding: "4px",
        transition: "background 0.2s, color 0.2s",
        ...((variant === "filled" || variant === "gradient") && {
          border: "none",
          color: "white",
        }),
        ...(variant === "filled" && {
          background: palette.secondary.main,
        }),
        ...(variant === "gradient" && {
          background: gradient.main,
          transition: "none",
          "&:hover, &[data-selected=true]": {
            background: palette.secondary.main,
          },
        }),
        ...(variant === "outlined" && {
          border: `1px solid ${palette.secondary.main}`,
          color: palette.secondary.main,
          "&:hover, &[data-selected=true]": {
            background: palette.secondary.main,
            color: palette.secondary.contrastText,
          },
        }),
        ...(variant === "transparent" && {
          background: "none",
          color: "white",
          "&:hover, &[data-selected=true]": {
            background: "white",
            color: palette.neutral.n8,
          },
        }),
        ...(style as object),
      }}
      {...props}
    >
      {children}
    </Base>
  );
};

export default Button;
