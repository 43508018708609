import React from 'react';
import Nav from "../layout/nav";
import { FlexColumn } from "../components/general/flex";

import Banner from "../layout/banner";
import Features from "../layout/features";
import Pricing from "../layout/pricing";
import { ScreenStoreProvider } from "../context/screen-store";
import Footer from "../layout/footer";

export default function Home() {
  return (
    <>
      <ScreenStoreProvider>
        <Nav />
        <FlexColumn
          style={{
            height: "100%",
            width: "100%",
            overflow: "hidden",
            position: "relative",
            zIndex: 1,
          }}
        >
          {/* <BlackBox>
        <Loading width="100px" height="100px"/>
        </BlackBox> */}
          <Banner />
          <Features />
          <Pricing />
          <Footer />
        </FlexColumn>
      </ScreenStoreProvider>
    </>
  );
}
