import React, { PropsWithChildren } from "react";
import Base from "./base";

export const Flex: CustomComponent<PropsWithChildren<{}>> = ({
  style,
  ...props
}) => {
  return (
    <Base style={{ display: "flex", ...(style as object) }} {...props}>
      {props.children}
    </Base>
  );
};

export const FlexColumn: CustomComponent<PropsWithChildren<{}>> = ({
  style,
  ...props
}) => {
  return (
    <Flex style={{ flexFlow: "column", ...(style as object) }} {...props}>
      {props.children}
    </Flex>
  );
};

export default Flex;
